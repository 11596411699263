<template>
  <div class="layout-news">
    <BlockHeader :links="headerLinks" />
    <!-- NewsArticleHero -->
    <UtilWrapperLayout>
      <UtilGrid>
        <div v-if="extraContent" class="layout-news__hero-article">
          <div
            class="layout-news__hero-article-back"
            :style="{
              color: extraContent.department?.content?.color_4,
            }"
            @click="router.back()"
            @keypress="router.back()"
          >
            <ProviderColorSchema
              :color-schema="colorSchema"
              style="display: flex;"
            >
              <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
                <IonIcon icon-name="chevron-left" :color-schema="colorSchema" />
              </ProviderColorSchema>
              <p>
                {{ getStoryblokTranslation('general', 'back_to_overview') }}
              </p>
            </providercolorschema>
          </div>

          <div class="layout-news__hero-article-image">
            <AtomImage
              v-if="extraContent?.image_video?.filename"
              class="layout-news__hero-article-image"
              :image="extraContent.image_video"
              provider="storyblok"
              :lazy="false"
              height="518px"
              width="960px"
            />
          </div>

          <div
            v-if="isArrayNotEmpty(extraContent?.tags)"
            class="layout-news__hero-article-tags"
          >
            <p
              v-for="tag in computedTags"
              :key="tag.index"
              class="layout-news__hero-article-tag"
              :style="{
                'background-color': extraContent.department.content.color_11,
                color: extraContent.department.content.color_6,
              }"
            >
              {{ tag.name }}
            </p>
          </div>

          <div
            v-if="extraContent?.department?.name || extraContent?.type?.name"
            class="layout-news__hero-article-department-type"
            :style="{
              color: extraContent.department?.content?.color_4,
            }"
          >
            <p v-if="extraContent?.department?.name">
              {{ computedDepartment }}
            </p>
            <p v-if="extraContent?.type?.name">
              |
            </p>
            <p v-if="extraContent?.type?.name">
              {{ computedType }}
            </p>
          </div>

          <AtomHeading
            v-if="extraContent?.title"
            class="layout-news__hero-article-title"
            :class="{ 'has-subtitle': extraContent.sub_title }"
            :text="extraContent.title"
            html-tag="h2"
            font-size="h2"
            :style="{
              color: extraContent.department?.content?.color_2,
            }"
          />

          <AtomHeading
            v-if="extraContent?.sub_title"
            class="layout-news__hero-article-subtitle"
            :text="extraContent.sub_title"
            html-tag="h2"
            font-size="h2"
            :style="{
              color: extraContent.department?.content?.color_3,
            }"
          />

          <div class="layout-news__hero-article-details">
            <p
              :style="{
                color: extraContent.department?.content?.color_3,
              }"
            >
              {{ dayjs(firstPublishedAt).locale(currentLocale).format('DD. MMMM YYYY') }}
            </p>
            <p v-if="extraContent.author">
              |
            </p>
            <span
              v-if="extraContent?.author"
              class="layout-news__hero-article-details-author"
              :style="`--c-department-4: ${extraContent.department.content.color_4};`"
            >
              {{ extraContent.author.name }}
            </span>
            <div class="layout-news__hero-article-details-share">
              <div
                @click="share()"
                @keypress="share()"
              >
                <ProviderColorSchema :color-schema="colorSchema">
                  <IonIcon
                    icon-name="share"
                  />
                </ProviderColorSchema>
              </div>
              <ClientOnly>
                <AtomSocialShare
                  v-for="network in networks"
                  :key="network.network"
                  :network="network.network"
                  :url="baseUrl + route.fullPath"
                  :title="extraContent.title"
                  :description="extraContent.sub_title"
                  :image="extraContent.image_video.filename"
                >
                  <ProviderColorSchema :color-schema="colorSchema">
                    <IonIcon
                      :icon-name="network.network"
                    />
                  </ProviderColorSchema>
                </AtomSocialShare>
              </ClientOnly>
              <p
                :style="{
                  color: extraData.department?.content?.color_4,
                }"
              >
                |
              </p>
              <div
                class="layout-news__reader"
                :style="{
                  color: extraData.department?.content?.color_4,
                }"
                @click="readText()"
                @keypress="readText()"
              >
                <div>{{ getStoryblokTranslation('general', 'read_article') }}</div>
                <ProviderColorSchema :color-schema="colorSchema" style="display: flex;">
                  <IonIcon
                    icon-name="reader"
                  />
                </ProviderColorSchema>
              </div>
            </div>
          </div>

          <AtomLead
            v-if="extraContent?.lead"
            class="layout-news__hero-article-lead"
            :text="extraContent.lead"
            :style="{
              color: extraContent.department?.content?.color_3,
            }"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>

    <ProviderColorSchema :color-schema="colorSchema">
      <slot />
    </ProviderColorSchema>

    <UtilWrapperLayout
      v-if="isArrayNotEmpty(similarArticles)"
      class="layout-news__similar-article-wrapper"
    >
      <UtilGrid class="layout-news__grid">
        <div class="layout-news__similar-article is-wide">
          <div class="layout-news__similar-article-head">
            <AtomHeading
              class="layout-news__similar-article-title"
              :text="getStoryblokTranslation('general', 'similar_articles')"
              html-tag="h3"
              font-size="h3"
            />
            <div class="layout-news__controls">
              <IonIcon
                v-if="!isBeginning"
                icon-name="chevron-left"
                @click="changeSlide('prev')"
              />
              <p>
                {{ activeIndex }}/{{ totalSlides }}
              </p>
              <IonIcon
                v-if="activeIndex < totalSlides"
                icon-name="chevron-right"
                @click="changeSlide('next')"
              />
            </div>
          </div>
        </div>
        <div class="layout-news__wrapper is-wide">
          <div class="layout-news__similar-article-swiper-wrapper">
            <Swiper
              :slides-per-view="clientDeviceStore.isMobile ? 'auto' : 2.8"
              :space-between="20"
              :grab-cursor="true"
              class="layout-news__swiper"
              @swiper="onSwiper"
              @slide-change="onSlideChange"
            >
              <SwiperSlide
                v-for="article in similarArticles"
                :key="article._uid"
                class="layout-news__swiper-card"
              >
                <ProviderColorSchema :color-schema="colorSchema">
                  <BlockItemTeaser
                    :item="{ ...article }
                    "
                    news-section
                  />
                </ProviderColorSchema>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </UtilGrid>
    </UtilWrapperLayout>

    <BlockServiceMenu
      v-if="serviceMenu?.show_service_navigation"
      :data="serviceMenu"
      :form-data="newsletterForm"
    />
    <BlockFooter v-bind="footer" />
  </div>
</template>

<script setup>
import { useSpeechSynthesis } from '@vueuse/core';

defineProps({
    extraData: {
        type: Object,
        default: () => {},
    },

    firstPublishedAt: {
        type: String,
        default: '',
    },
});

const { locale: currentLocale, defaultLocale } = useI18n();

const serviceMenu = getStoryblokSetting('service');
const newsletterForm = getStoryblokSetting('newsletter');
const footer = getStoryblokSetting('footer');
const links = getStoryblokSetting('navigation', 'header_navigation');

const headerLinks = computed(() => {
    if (links.value) {
        return useMapStoryblokLinks(links.value, currentLocale);
    }

    return [];
});

/*
    Variables
*/
const { dayjs } = useDayJs();
const route = useRoute();
const router = useRouter();
const runtimeConfig = useRuntimeConfig();
const baseUrl = computed(() => runtimeConfig.public.BASE_URL);
const storyblokContentStore = useStoryblokContentStore();
const sbStoryId = computed(() => storyblokContentStore.getStoryId);
const extraContent = computed(() => storyblokContentStore.getExtraContent);
const strokeColor = computed(() => extraContent.value?.department?.content?.color_4);

const networks = [
    { network: 'linkedin', icon: ['linkedin'] },
];

const colorSchema = computed(() => extraContent.value?.department?.content.color_schema);

/*
    Functions
*/
const share = () => {
    // share url mobile
    if (navigator.share) {
        navigator.share({
            title: extraContent.value.title,
            text: extraContent.value.sub_title,
            url: baseUrl + route.fullPath,
        })
            .then(() => {})
            .catch((error) => useSentryError(error));
    } else {
        // share url desktop
        window.location.href = `mailto:?subject=${extraContent.value.title}&body=${extraContent.value.sub_title}%0D%0A%0D%0A${baseUrl.value + route.fullPath}`;
    }
};

/*
    Init Swiper
*/
const { Swiper, SwiperSlide } = useSwiperjs();
const swiperController = ref(null);
const totalSlides = ref(0);
const activeIndex = ref(1);
const isBeginning = ref(true);
const isEnd = ref(false);
const clientDeviceStore = useClientDeviceStore();
const mainText = computed(() => mainContentNewsText.value);

const onSwiper = (e) => {
    swiperController.value = e;
};

// watch swiperController to init totalSlides
watchEffect(() => {
    if (swiperController.value) {
        totalSlides.value = swiperController.value.snapGrid.length - 1;

        if (clientDeviceStore.isMobile) {
            totalSlides.value += 1;
        }
    }
});

const slideFromEnd = () => {
    if (isEnd.value && !clientDeviceStore.isMobile) {
        isEnd.value = false;
        swiperController.value.slideTo(totalSlides.value - 2);
    }
};

const changeSlide = (dir) => {
    if (dir === 'next') {
        swiperController.value.slideNext();
    }

    if (dir === 'prev') {
        if (isEnd.value && !clientDeviceStore.isMobile) {
            slideFromEnd();
        } else {
            swiperController.value.slidePrev();
        }
    }
};

const onSlideChange = (slide) => {
    activeIndex.value = slide.realIndex + 1;
    isBeginning.value = slide.isBeginning;

    if (!isEnd.value && activeIndex.value === totalSlides.value && !clientDeviceStore.isMobile) {
        // slide to the last slide + 1 to show the last slide
        if (swiperController.value) {
            swiperController.value.slideTo(totalSlides.value);
            isEnd.value = true;
        }
    }
};

const storyblokApi = useStoryblokApi();
const language = computed(() => (currentLocale.value === defaultLocale.value ? '' : currentLocale.value));

const {
    data: similarArticles,
    error: getSimilarArticlesError,
} = useLazyAsyncData(
    `get-similar-articles-${getUid()}`,
    async () => storyblokApi.get('cdn/stories/', {
        starts_with: 'news/news-article/',
        version: 'published',
        sort_by: 'first_published_at:desc',
        per_page: 5,
        filter_query: {
            department: {
                in: extraContent.value?.department.uuid,
            },
        },
        excluding_ids: sbStoryId.value,
        resolve_relations: 'News.department,News.tags,News.author,News.type',
        language: language.value,
    }),
    {
        transform: (res) => {
            const articlesData = res.data.stories.map((article) => {
                const department = article.content?.department || {};
                const imageVideo = article.content?.image_video || {};

                return {
                    article: {
                        title: article.content.title,
                        cached_url: article.default_full_slug,
                        story: {
                            alternates: article.translated_slugs,
                        },
                        date: article.first_published_at,
                        department: {
                            name: department.translated_slugs?.find(
                                (slug) => slug.lang === currentLocale.value,
                            )?.name || department?.name,
                            color_3: department.content?.color_3,
                        },
                        image: {
                            ...imageVideo,
                            provider: 'storyblok',
                        },
                    },
                    size: 'small',
                };
            });

            // reset swiper
            if (swiperController?.value) {
                swiperController.value.slideTo(0);
            }

            return articlesData;
        },
        watch: [extraContent],
    },
);

/*
    Error handling
*/
watch(() => getSimilarArticlesError, (error) => {
    if (error) {
        useSentryError(error, {
            starts_with: 'news/news-article/',
            version: 'published',
            sort_by: 'first_published_at:desc',
            per_page: 5,
            filter_query: {
                department: {
                    in: extraContent.value?.department.uuid,
                },
            },
            excluding_ids: sbStoryId.value,
            resolve_relations: 'News.department,News.tags,News.author,News.type',
            language: language.value,
        });
    }
});

const computedTags = computed(() => extraContent.value?.tags?.map((tag) => ({
    ...tag,
    translated_slugs: tag.translated_slugs?.find((slug) => slug.lang === currentLocale.value),
    name: tag.translated_slugs?.find((slug) => slug.lang === currentLocale.value)?.name || tag.name,
})));

const computedType = computed(() => extraContent.value?.type?.translated_slugs?.find(
    (slug) => slug.lang === currentLocale.value,
)?.name || extraContent.value?.type.name);

const computedDepartment = computed(() => extraContent.value?.department?.translated_slugs?.find(
    (slug) => slug.lang === currentLocale.value,
)?.name || extraContent.value?.department.name);

/*
    Text to Speech with Chunking
*/
const textChunks = ref([]); // To hold the chunks of text
const selectedVoice = ref(null);

// Initialize the speech synthesis object
const speech = useSpeechSynthesis('', {
    pitch: 1,
    rate: currentLocale.value === 'fr' ? 1 : 1.1,
    volume: 1,
});

// Function to split text into chunks
const chunkText = (text, chunkSize = 180) => {
    const chunks = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
        let nextChunk = text.slice(currentIndex, currentIndex + chunkSize);

        // Find the last period or comma before the chunk ends
        const lastPunctuationIndex = Math.max(
            nextChunk.lastIndexOf('.'),
            nextChunk.lastIndexOf(','),
            nextChunk.lastIndexOf('!'),
            nextChunk.lastIndexOf('?'),
            nextChunk.lastIndexOf(':'),
        );

        if (currentIndex + chunkSize < text.length && lastPunctuationIndex !== -1) {
            nextChunk = nextChunk.slice(0, lastPunctuationIndex + 1);
        }

        chunks.push(nextChunk);
        currentIndex += nextChunk.length;

        // Skip any trailing spaces after the chunk
        while (text[currentIndex] === ' ') {
            currentIndex += 1;
        }
    }

    return chunks;
};

// Function to set voice based on the current locale
const setVoice = () => {
    const voices = window.speechSynthesis.getVoices();
    if (currentLocale.value === 'de') {
        selectedVoice.value = voices.find((voice) => voice.name === 'Google Deutsch');
    } else if (currentLocale.value === 'fr') {
        selectedVoice.value = voices.find((voice) => voice.name === 'Google français');
    } else if (currentLocale.value === 'it') {
        selectedVoice.value = voices.find((voice) => voice.name === 'Google italiano');
    } else {
        selectedVoice.value = voices.find((voice) => voice.name === 'Google UK English Female');
    }

    // If the voice is found, update the speech object
    if (selectedVoice.value) {
        speech.utterance.value.voice = selectedVoice.value;
    }
};

// Fetch the voices when the component is mounted
onMounted(() => {
    if (window.speechSynthesis.onvoiceschanged !== undefined) {
        window.speechSynthesis.onvoiceschanged = setVoice();
    } else {
        setVoice();
    }
});

// Function to read each chunk
const readNextChunk = () => {
    if (textChunks.value.length > 0) {
        const currentChunk = textChunks.value.shift();
        speech.utterance.value.text = currentChunk;
        speech.speak();
        speech.utterance.value.onend = () => {
            if (textChunks.value.length > 0) {
                readNextChunk(); // Read the next chunk when current finishes
            } else {
                speech.stop();
            }
        };
    }
};

// Function to start reading the full text
const readText = () => {
    if (speech.isPlaying.value) {
        speech.stop();
    } else {
        textChunks.value = chunkText(`${extraData.value.lead + mainText.value}`); // Split the text into chunks
        readNextChunk();
    }
};

// watch currentLocale to update the voice
watch(currentLocale, () => {
    setVoice();
    speech.stop();
});
</script>

<style lang="scss">
.layout-news {
    position: relative;
    background-color: var(--c-background);
}

.layout-news__hero-article {
    @include default-content-columns;

    margin-top: 78px;
    margin-bottom: 33px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.layout-news__hero-article-details-share {
    display: flex;
    column-gap: 29px;

    path {
        stroke: v-bind(strokeColor);
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-news__reader {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    gap: 10px;
    line-height: var(--l-height--richtext);
}

.layout-news__controls {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    p {
        margin-top: 6px;

        @include mobile {
            margin-top: 1px;
        }
    }

    .ion-icon {
        cursor: pointer;
    }
}

.layout-news__swiper {
    display: flex;

    @include tablet {
        display: flex;
        overflow: visible;
        flex-direction: row;

        .layout-news__swiper-card {
            min-width: 270px;
        }

        .block-item-teaser {
            width: 270px !important;
            margin-bottom: 10px;
        }

        .swiper-slide {
            width: 270px !important;
        }
    }
}

.layout-news__hero-article-back {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 10px;
    color: var(--c-petrol-4);
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    .ion-icon {
        transition: all 0.3s ease;
    }

    p {
        padding-top: 1px;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}

.layout-news__hero-article-image {
    @include fluid('margin-bottom', 10px, 19px);

    height: 518px;

    @include mobile {
        height: 152px;
    }
}

.layout-news__hero-article-tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    gap: 10px;

    @include mobile{
        margin-bottom: 20px;
    }
}

.layout-news__hero-article-tag {
    padding: 6px 12px;
    border-radius: 4px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news__hero-article-department-type {
    @include fluid('margin-bottom', 5px, 10px);

    display: flex;
    column-gap: 9px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--ri--f-size--richtext);
}

.layout-news__hero-article-details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    column-gap: 9px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    row-gap: 5px;

    @include mobile {
        margin-bottom: 40px;
    }
}

.layout-news__hero-article-title {
    @include fluid('margin-bottom', 20px, 30px);

    &.has-subtitle {
        margin-bottom: 0;
    }
}

.layout-news__hero-article-subtitle {
    @include fluid('margin-bottom', 20px, 30px);
}

.layout-news__similar-article-wrapper {
    overflow: hidden;
}

.layout-news__similar-article {
    @include default-content-columns;

    display: flex;
    flex-direction: column;
}

.layout-news__grid {
    margin-bottom: 72px;

    &.block-grid {
        row-gap: 20px;
    }
}

.layout-news__similar-article-head {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px 0 30px;
    background-color: var(--c-white);
    color: v-bind(colorMain);
}

.layout-news__similar-article-title {
    &.atom-heading {
        font-family: var(--f-family--black);
    }
}

.layout-news__similar-article-swiper-wrapper {
    width: 100%;
}

.layout-news__wrapper {
    @include default-content-columns;
}

.layout-news__imgWrapper {
    overflow: hidden;

    img {
        height: 201px;
    }
}

.layout-news__tag {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    width: 125px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news__video-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    width: 92px;
    height: 50px;
    align-items: center;
    justify-content: space-around;
    background: rgb(182,182,182, 0.9);
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--tag);
    line-height: var(--l-height--tag);
}

.layout-news__date {
    margin-top: 10.87px;
    color: v-bind(colorDate);
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news__title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.layout-news__lead {
    p {
        margin-bottom: 20px;
    }
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news__type {
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.layout-news__link {
    padding-top: 20px;
}

.layout-news__icon {
    transition: all 0.2s ease-out;
}

.layout-news__img {
    transition: transform 0.2s ease-out;
}

.layout-news__similar-article-wrapper-item {
    &:hover {
        .layout-news__img {
            transform: scale(1.05);
        }

        .layout-news__video-tag-icon {
            transform: none;
        }

        .layout-news__icon {
            transform: translateX(10px);
        }
    }
}

.layout-news__hero-article-details-author {
    color: var(--c-department-4);
}
</style>
